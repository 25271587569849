<template>
  <section>
    <v-row v-if="!loading">
      <v-col cols="12" xl="8" lg="9">
        <div class="text-h5 ml-2 mb-5">Manage Results</div>
        <v-simple-table fixed-header loading="true" class="simple-table pt-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left caption"></th>
                <th class="text-left caption">Email</th>
                <th class="text-left caption">Name</th>
                <th class="text-left caption">Assessments</th> 
                <th class="text-left caption">Progress</th> 
                <th class="text-left caption"></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="item in results.data" :key="item.id">
                <td>
                  <v-avatar color="success" size="36"></v-avatar>
                </td>
                <td class="">{{ item.student.email }}</td>
                <td class="">{{ item.student.name }}</td>
                <td class="font-weight-bold"> 
                  {{ item.total_taked }} / {{ item.total_assessments }}
                </td>
                <td class="">
                  <v-progress-linear height="10" rounded 
                  :value="(item.total_taked / item.total_assessments) * 100"/>
                </td>
                <td class="">
                  <v-btn @click="view(item.student.id)" small color="primary" text class="button">View</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <Paginate class="mt-5" store="admin" collection="results"/>
      </v-col>
    </v-row>
    <div v-else class="body-2">Loading...</div>
  </section>
</template>

<script>
var CryptoJS = require("crypto-js");
import { mapActions, mapState } from 'vuex'
import Paginate from '../components/result/ResultPaginate.vue'

export default {
  components: {
    Paginate
  },
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapState('admin', {
      results: (state) => state.results
    })
  },  
  mounted() {
    this.getResults({
      page: 0
    }).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('admin', ['getResults']),

    view(id) {
      let key = CryptoJS.AES.encrypt(id.toString(), 'secret').toString()
      console.log(key);
      this.$router.push({
        name: 'Admin Result Details',
        params: {
          id: key
        }
      })
    }
  }
}
</script>